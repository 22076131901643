import { call, put, takeEvery } from "redux-saga/effects";
import axios from "../../../util/http-util";
import { VISION_DETAIL_ACTION, fetchVisionDetailFail,fetchVisionDetailSuccess } from "./vision-detail-action";

function* fetchVisionDetailSaga() {
    try {
        const {data} = yield call(axios.get("items.json?t="+new Date().getTime()))
        yield put(fetchVisionDetailSuccess(data))
    } catch (error) {
        yield put(fetchVisionDetailFail(error))
    }
}

const visionDetailSaga = [
    takeEvery(VISION_DETAIL_ACTION.FETCH_VISION_DETAIL, fetchVisionDetailSaga)
]

export default visionDetailSaga;