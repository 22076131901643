const VISION_DETAIL_ACTION = {
    FETCH_VISION_DETAIL: 'FETCH_VISION_DETAIL',
    FETCH_VISION_DETAIL_SUCCESS: 'FETCH_VISION_DETAIL_SUCCESS',
    FETCH_VISION_DETAIL_FAIL: 'FETCH_VISION_DETAIL_FAIL',
}

const fetchVisionDetail = () => ({
    type: VISION_DETAIL_ACTION.FETCH_VISION_DETAIL
})

const fetchVisionDetailSuccess = () => ({
    type: VISION_DETAIL_ACTION.FETCH_VISION_DETAIL_SUCCESS,
});

const fetchVisionDetailFail = () => ({
    type: VISION_DETAIL_ACTION.FETCH_VISION_DETAIL_FAIL,
})

export {
    VISION_DETAIL_ACTION,
    fetchVisionDetail,
    fetchVisionDetailSuccess,
    fetchVisionDetailFail
}