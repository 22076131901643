const CATEGORY_ACTION = {
    FETCH_CATEGORY : 'FETCH_CATEGORY',
    FETCH_CATEGORY_SUCCESS: 'FETCH_CATEGORY_SUCCESS',
    FETCH_CATEGORY_FAIL: 'FETCH_CATEGORY_FAIL',
    FILTER_CATEGORY: 'FILTER_CATEGORY'
}

const fetchCategory = () => ({
    type: CATEGORY_ACTION.FETCH_CATEGORY,
})

const fetchCategorySuccess = (data) =>({
    type: CATEGORY_ACTION.FETCH_CATEGORY_SUCCESS,
    payload: data
})

// When get the category fail, need to set a default category
const fetchCategoryFail = () =>({
    type: CATEGORY_ACTION.FETCH_CATEGORY_FAIL,
})

const filterCategory = (categoryId) => ({
    type: CATEGORY_ACTION.FILTER_CATEGORY,
    categoryId,
})

export{
    CATEGORY_ACTION,
    fetchCategory,
    fetchCategorySuccess,
    fetchCategoryFail,
    filterCategory
}

