import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { filterVisionList } from "./redux/vision-list-action";

const VisionList = (props) => {
    const dispatch = useDispatch();
    const visionListResult = useSelector(state => state.visionListReducer.visionList);
    const categoryList = useSelector(state => state.categoryReducer.categoryList?.data?.categories)
    const visionListScroll = useRef()
    
    useEffect(() => {
        let selectedCategory = categoryList.find((category) => category.name == props.categoryName)
        dispatch(filterVisionList(selectedCategory.id))
    }, []);

    // Focus on visionListDiv
    useEffect(() => {
        let selectedCategory = categoryList.find((category) => category.name == props.categoryName)
        dispatch(filterVisionList(selectedCategory.id))
        visionListScroll.current.scrollIntoView({ behavior: "smooth" });
    }, [props.categoryName])

    return (
        <div>
            <div className="container" ref={visionListScroll}>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-4 g-4" >
                    {
                        visionListResult?.map((vision, i) => {
                            return (
                                <div className="col">
                                    <Link to={`/${props.categoryName}/${(vision.model).split('/')[0]}`} className="vs-card-label">
                                        <div className="card border-0 shadow-lg" style={{ 'height': '250px' }}>
                                            <img src={'http://resource.72bang.cn/' + vision.preview} className="card-img-top" alt="..." style={{ 'height': '240px', 'object-fit': 'cover' }} />
                                            <div className="card-body volufab-list-card p-1">
                                                <span className="card-title">
                                                    {vision.name}
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            )
                        }
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default VisionList;