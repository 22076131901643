import Header from "../header/header";
import * as THREE from "three";
import { useEffect, useRef } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { fetchVisionDetail, fetchVisionDetailFail, fetchVisionDetailSuccess } from "./redux/vision-detail-action";
import loading from '../../../src/loading.svg';
import { Link } from "react-router-dom";
import "../../../src/App.css";

export default function VisionDetailAr() {
    let { id, categoryName } = useParams();

    useEffect(()=>{
        console.log("id====" + id)
        var ar = document.getElementById('ar');
        var maxHeight = window.innerHeight- document.getElementById('header').clientHeight;
        ar.style.height = maxHeight + 'px';
        console.log('*** ' + maxHeight + ' ***');
        var arSrc =  `/preview/?id=${id}`;
        ar.src = arSrc;
    });

    let arButtonClass = 'ar-button-pc';
    if (!IsPC()){
        arButtonClass = 'ar-button-mobile';
    }

    function IsPC() {
        var userAgentInfo = navigator.userAgent;
        console.log(userAgentInfo);
        var Agents = ["Android", "iPhone",
                    "SymbianOS", "Windows Phone",
                    "iPad", "iPod"];
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                flag = false;
                break;
            }
        }
        return flag;
    }

    return (
        <div>
            <Header categoryName={categoryName}/>
            <div className="container">
                <div className="pt-2"></div>
                <div className="pt-5">
                    <a className={"position-absolute z-index-1000 btn btn-volufab btn-sm m-3 px-3" +' '+`${arButtonClass}`}  href={`https://jmprb.zappar.io/1105982230057541051/1.0.1/?name=${id}`} role="button">
                    
                        {/* <i className="bi bi-badge-ar-fill"></i> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-badge-ar-fill" viewBox="0 0 16 16">
                            <path d="m6.031 8.574-.734-2.426h-.052L4.51 8.574h1.52zm3.642-2.641v1.938h1.033c.66 0 1.068-.316 1.068-.95 0-.64-.422-.988-1.05-.988h-1.05z" />
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm4.265 5.458h2.004L6.739 11H8L5.996 5.001H4.607L2.595 11h1.2l.47-1.542zM8.5 5v6h1.173V8.763h1.064L11.787 11h1.327L11.91 8.583C12.455 8.373 13 7.779 13 6.9c0-1.147-.773-1.9-2.105-1.9H8.5z" />
                        </svg>
                    </a>
                    <iframe id='ar'
                        style={{ width: '100%', border:'none' }}
                        onLoad={() => {
                            // console.log('load completed.');
                            // var ar = document.getElementById('ar');
                            // var maxHeight = window.innerHeight;
                            // ar.style.height = maxHeight + 'px';
                        }}
                        src=""
                        width="100%"
                        height="100%"
                        scrolling="no"
                        frameBorder="0"
                        allow="camera *"
                    />
                </div>
            </div>


        </div>
    );

}
