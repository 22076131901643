
import { CATEGORY_ACTION } from "./category-action";

const initState ={
    categoryList: [],
    categoryId: -1,
    isLoading: true
}
export default function categoryReducer(state=initState, action) {
    switch (action.type) {
        case CATEGORY_ACTION.FETCH_CATEGORY:
            
            return {
                ...state
            }
        
        case CATEGORY_ACTION.FETCH_CATEGORY_SUCCESS:
            action.payload['data']['categories'].unshift({"id":-1,"name":"全部"})
            action.payload['data']['categories'].unshift({"id":99,"name":"关于"})
            action.payload['data']['categories'].sort((a,b)=>{return a.id-b.id})
            
            return{
                ...state,
                categoryList : action.payload,
                isLoading: false
            }
        
        case CATEGORY_ACTION.FETCH_CATEGORY_FAIL:
            return{
                ...state,
                // Todo dummy
                categoryList : [
                    {
                        id: 1,
                        name: 'test'
                    }
                ],
                isLoading: false
            }

        case CATEGORY_ACTION.FILTER_CATEGORY:
            return{
                ...state,
                categoryId: action.categoryId
            }
    
        default:
            return state
    }
}