import { all } from 'redux-saga/effects';
import visionDetailSaga from "../component/vision-detail/redux/vision-detail-saga";
import visionListSaga from "../component/vision-list/redux/vision-list-saga";
import categorySaga from "../component/header/redux/category-saga";

function* rootSaga() {
  yield all([...visionDetailSaga, ...visionListSaga, ...categorySaga]);
}

export default rootSaga;
