import { VISION_DETAIL_ACTION } from "./vision-detail-action";

const initState = {
    isLoading1: true,
    init: true
}
export default function visionDetailReducer(state = initState, action) {
    console.log('=====visionDetailReducer' + action.type + JSON.stringify(state))
    switch (action.type) {
        case VISION_DETAIL_ACTION.FETCH_VISION_DETAIL:
            return {
                ...state,
                isLoading1: true
            }
        case VISION_DETAIL_ACTION.FETCH_VISION_DETAIL_SUCCESS:

            return {
                ...state,
                isLoading1: false
            }


        default:
            return state
    }
}