import React, { useEffect } from 'react';
import Header from '../header/header';
import VisionList from '../vision-list/vision-list';
import loading from '../../../src/loading.svg';
import "../../../src/App.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Carousel from "../carousel/carousel";
import {fetchCategory} from "../../component/header/redux/category-action"
import About from '../about/about';


const Home = () => {

    const isLoadingCategory = useSelector(state => state.categoryReducer.isLoading)

    const {categoryName} = useParams()
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(fetchCategory())
    },[])



    return (
        <div>
            {isLoadingCategory ?
                (
                    <div className="App-header">
                        <img src={loading} className="App-logo" alt="logo" />
                    </div>
                ) : <div>
                    <Header categoryName={categoryName?categoryName:'全部'}/>
                    {categoryName === '关于' ?
                        <About/>
                    :
                        <span>
                        <Carousel/>
                        <VisionList categoryName={categoryName?categoryName:'全部'}/>
                        </span>  
                }
                    
                </div>}
            
        </div>
    )
}

export default Home