const Carousel = () => {
    return (
        <div className="container-fluid pt-5 pb-2">
            <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active position-relative">
                        <img src="/assets/img/sample1.jpeg" className="d-block w-100" alt="..." />
                        <div className="vs-pc-only position-absolute top-50 start-0 ms-3 translate-middle-y">
                            <h1 className="vs-carousel-title">领先的容积数据展示平台</h1>
                            <h5 className="vs-carousel-title pt-3">管理你的资产，随时随地的上传下载内容，</h5>
                            <h5 className="vs-carousel-title">将他们应用在电影和增强现实中，并与他人建立伙伴关系</h5>
                        </div>
                        <div className="vs-sp-only">
                            <h5 className="vs-carousel-title pt-3">领先的容积数据展示平台</h5>
                            <p className="vs-carousel-title pt-3">管理你的资产，随时随地的上传下载内容，将他们应用在电影和增强现实中，并与他人建立伙伴关系</p>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    )
}

export default Carousel;