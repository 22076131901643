import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategory, filterCategory } from "./redux/category-action";
import { filterVisionList } from "../vision-list/redux/vision-list-action";
import { Link } from "react-router-dom";

const Header = (props) => {

  const dispatch = useDispatch()
  const categoryList = useSelector(state => state.categoryReducer.categoryList?.data?.categories)

  useEffect(() => {
    dispatch(fetchCategory())
  }, [])

  return (
    <nav id="header" className="navbar navbar-expand-md shadow-sm navbar-dark fixed-top vs-menu p-1">
      <div className="container-fluid">
        <a className="navbar-brand" href="/"><img className="vs-logo" src="/assets/img/logo.png" /></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav ms-3 me-auto mb-2 mb-md-0">
            {
              categoryList?.map((category) => {
                return (
                  <li key={category.id} className="nav-item">
                    <Link to={`/${category.name}`} href="javascript:void(0)" className={`nav-link pb-1 active ${props.categoryName == category.name ? 'vs-border text-light' : null}`} aria-current="page" >
                      <h5 className="vs-header-title">{category.name}</h5>
                    </Link>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header