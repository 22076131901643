const VISION_LIST_ACTION = {
    FILTER_VISION_LIST: 'FILTER_VISION_LIST',
    FILTER_VISION_LIST_SUCCESS: 'FILTER_VISION_LIST_SUCCESS',
    FILTER_VISION_LIST_FAILURE : 'FETCH_VISION_LIST_FAILURE',
}

const filterVisionListSuccess = (data) => ({
    type: VISION_LIST_ACTION.FILTER_VISION_LIST_SUCCESS,
    payload: data
});

const filterVisionListFail = (error) => ({
    type: VISION_LIST_ACTION.FILTER_VISION_LIST_FAILURE,
    error: error
})

const filterVisionList = (categoryId) => ({
    type: VISION_LIST_ACTION.FILTER_VISION_LIST,
    categoryId
})

export{
    VISION_LIST_ACTION,
    filterVisionList,
    filterVisionListSuccess,
    filterVisionListFail
}