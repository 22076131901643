import { call, put, takeEvery } from "redux-saga/effects";
import httputil from "../../../util/http-util";
import { CATEGORY_ACTION, fetchCategoryFail, fetchCategorySuccess } from "../redux/category-action";

function* fetchCategorySaga() {
    try {
        const categoryList = yield call(() => httputil.get('categories.json?t='+new Date().getTime()))
        yield put(fetchCategorySuccess(categoryList))
    } catch (error) {
        console.log('error='+error)
        yield put(fetchCategoryFail(error))
    }
}

const categorySaga =[
    takeEvery(CATEGORY_ACTION.FETCH_CATEGORY, fetchCategorySaga)
]

export default categorySaga