import { combineReducers } from 'redux';
import visionListReducer from '../component/vision-list/redux/vision-list-reducer';
import categoryReducer from "../component/header/redux/category-reducer";
import visionDetailReducer from "../component/vision-detail/redux/vision-detail-reducer";

const reducer = combineReducers({
  visionListReducer,
  categoryReducer,
  visionDetailReducer
});

export default reducer;
