import Header from "../header/header";
import * as THREE from "three";
import { useEffect, useRef } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { fetchVisionDetail, fetchVisionDetailFail, fetchVisionDetailSuccess } from "./redux/vision-detail-action";
import loading from '../../../src/loading.svg';
import "../../../src/App.css";

export default function VisionDetailAr() {

    // let { categoryName, id } = useParams();

    // const visionList = useSelector(state => state.visionListReducer.allVisionList)
    // const vision = visionList.find((vision) => vision.name == id)

    // let camera, scene, renderer, clock, mixer;
    // let vision3DObj = new THREE.Object3D();

    // const dispatch = useDispatch();
    // const isLoading = useSelector(state => state.visionDetailReducer.isLoading1)
    // const init = useSelector(state => state.visionDetailReducer.init)

    // let mount = useRef(null);

    // useEffect(() => {
    //     console.log('dipath 111111')
    //     dispatch(fetchVisionDetail())
    //     // 设置一个透视摄像机
    //     camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    //     camera.position.z = 5

    //     scene = new THREE.Scene();
    //     // 设置灯光
    //     const directionalLight = new THREE.AmbientLight(0xffffff, 4);
    //     scene.add(directionalLight);
    //     directionalLight.position.set(2, 5, 5);

    //     clock = new THREE.Clock();
    //     // 加载模型
    //     const gltfloader = new GLTFLoader();
    //     gltfloader.load('http://72studio.jcsureyes.com/' + vision.model, function (gltf) {
    //         gltf.scene.scale.set(1, 1, 1); //设置缩放

    //         vision3DObj.add(gltf.scene);

    //         scene.add(gltf.scene);
    //         // Todo：加载动画，但是似乎源文件中的动画轨迹找不到
    //         mixer = new THREE.AnimationMixer(gltf.scene)
    //         gltf.animations.forEach((clip) => {
    //             console.log('mixer=' + JSON.stringify(mixer))
    //             console.log('clip=' + JSON.stringify(clip))
    //             mixer.clipAction(clip).play()
    //         })
    //         dispatch(fetchVisionDetailSuccess())
    //     }, function (xhr) {
    //         console.log((xhr.loaded / xhr.total * 100) + '% loaded')

    //     }, function (error) {
    //         console.log('An error happened');
    //         dispatch(fetchVisionDetailFail())
    //     }
    //     );

    //     renderer = new THREE.WebGLRenderer({ antialias: true });
    //     renderer.setSize(window.innerWidth, window.innerHeight);
    //     mount.current.appendChild(renderer.domElement);

    //     const controls = new OrbitControls(camera, renderer.domElement);
    //     controls.addEventListener('change', render);
    //     controls.minDistance = 2; // 限制距离
    //     controls.maxDistance = 10;
    //     controls.target.set(0, 0, 0);  // 旋转中心点

    //     controls.minPolarAngle = 0.3 // 设置最小旋转角度
    //     controls.maxPolarAngle = Math.PI / 2 - Math.PI / 10

    //     controls.update();

    //     window.addEventListener('resize', onWindowResize);
    //     animate();

    //     return () => {
    //         // Callback to cleanup three js, cancel animationFrame, etc
    //     }

    // }, [init]);

    // function onWindowResize() {
    //     // 重新渲染
    //     camera.aspect = window.innerWidth / window.innerHeight;
    //     camera.updateProjectionMatrix();

    //     renderer.setSize(window.innerWidth, window.innerHeight);
    //     render();
    // }

    // function render() {
    //     vision3DObj.rotation.y += 0.01
    //     renderer.render(scene, camera);
    // }

    // function animate() {
    //     requestAnimationFrame(animate);
    //     var delta = clock.getDelta();
    //     if (mixer) mixer.update(delta);
    //     renderer.render(scene, camera);
    // }


    // return (
    //     <div className="container">
    //         <Header categoryName={categoryName} />
    //         {
    //             isLoading ?
    //                 (<div className="App-header">
    //                     <img src={loading} className="App-logo" alt="logo" />
    //                 </div>) : null
    //         }
    //         <div ref={mount} />
    //     </div>
    // )

    return (
        <iframe id='ar'
            style={{width:'100%', height:'100%'}}
            onLoad={() => {
                console.log('load completed.');
                var ar = document.getElementById('ar');
                var maxHeight = window.innerHeight;
                ar.style.height = maxHeight + 'px';
            }} 
            src="https://ygd.zappar.io/1122928229091204964/0.02/?name=202106062050111&z=-70" 
            width="100%" 
            height="100%"
            scrolling="no" 
            frameBorder="0"
            allow="camera *"
        />
      );

}