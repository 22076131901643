
import axios from 'axios';

const baseAxios = axios.create({
    baseURL: "https://resource.72bang.cn/",
    // baseURL: "/",
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: false,
});

baseAxios.interceptors.request.use(
    request => {
        // console.log('reuqest=' + JSON.stringify(request))
        return request
    }, 
    error => Promise.reject(error)
);

baseAxios.interceptors.response.use(
    response => {
        // console.log('response=' + JSON.stringify(response))
        return response
    },
    error => {
        console.log('error=' + JSON.stringify(error))
        Promise.reject(error)
    }
)

export default baseAxios
 
 