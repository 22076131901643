import { Route, Switch } from "react-router-dom";
import  Home  from "../component/home/home-container";
import  VersionDetail  from "../component/vision-detail/vision-detail";
import VisionDetailAr from "../component/vision-detail/vision-detail-ar";

export default function Router() {
    return (
        <Switch>
            <Route path="/:categoryName/:id/ar">
                <VisionDetailAr/>
            </Route>
            <Route path="/:categoryName/:id">
                <VersionDetail/>
            </Route>
            <Route path="/:categoryName">
                <Home/>
            </Route>
            <Route path="/ar">
                <VisionDetailAr/>
            </Route>
            <Route path="/">
                <Home/>
            </Route>
        </Switch>
    )
    
}