import { VISION_LIST_ACTION } from "./vision-list-action";

const initState = {
    isLoading: true,
    visionList: []
}

export default function visionListReducer(state = initState, action) {
    switch (action.type) {

        case VISION_LIST_ACTION.FILTER_VISION_LIST:
            return {
                ...state,
            }
        case VISION_LIST_ACTION.FILTER_VISION_LIST_SUCCESS:
            return {
                ...state,
                visionList: action.payload,
                isLoading: false
            }
        case VISION_LIST_ACTION.FILTER_VISION_LIST_FAILURE:
            return {
                ...state,
                isLoading: false
            }

        default:
            return state
    }
}