import { call, put, takeEvery } from "redux-saga/effects";
import httputil from "../../../util/http-util";
import { VISION_LIST_ACTION, filterVisionListFail, filterVisionListSuccess } from "./vision-list-action";
function* filterVisionListSaga(action) {
    try {
        const res = yield call(() => httputil.get("items.json?t=" + new Date().getTime()))
        let visionList = action.categoryId == -1 ? res.data.items : res.data.items.filter(vision => vision.catogory == action.categoryId)
        yield put(filterVisionListSuccess(visionList))
    } catch (error) {
        yield put(filterVisionListFail(error))
    }
}

const visionListSaga = [
    takeEvery(VISION_LIST_ACTION.FILTER_VISION_LIST, filterVisionListSaga)
]

export default visionListSaga;